import React from "react";
import PropTypes from "prop-types";
import postscribe from "postscribe";


function WidgetEmbed({script}) {
    const createScript = React.useCallback(() => {
        postscribe(
          "#scriptDiv",
          `<script src=${script}>
          </script>`
        );
      }, []);

      React.useEffect(() => {
        createScript();
      }, [createScript]);

      return <div className="container" id="scriptDiv" />;
}

WidgetEmbed.propTypes = {
    script: PropTypes.string
};

export default WidgetEmbed
